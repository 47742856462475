import {graphql} from "gatsby"
import Img from "gatsby-image"
import React, {useRef} from "react"
import { Helmet } from "react-helmet";
import { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// To add a cover:
// Add an image in your Google Doc first page header
// https://support.google.com/docs/answer/86629

class PageContent extends React.PureComponent {
  render (){
    return (
      <div className="page-layout">
        {this.props.cover && (
          <Img
            style={{width: "500px", marginBottom: "2rem"}}
            fluid={this.props.cover.image.childImageSharp.fluid}
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: `<div> ${this.props.html} </div>` }} />
        <div className="watermark" />
      </div>
    )
  }
}

const Page = ({
  data: {
    page: {
      name,
      cover,
      childMarkdownRemark: {html},
    },
  },
}) => {
  const componentRef = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleShare = () => {
    let href = window.location.href;
    if(href.indexOf("frame=true") < 0){
      if(href.indexOf("?") >= 0){
        href += "&frame=true"; 
      } else {
        href += "?frame=true";
      }
    }

    navigator.clipboard.writeText(href);
    toast.success("Link blev kopieret til udklipsholderen.", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      }
    );
  };

  return (
    <>
      <Helmet title={name} />
      <PageContent html={html} cover={cover} ref={componentRef} />
      <div className="print-button" onClick={handlePrint}>Print</div>
      <div className="share-button" onClick={handleShare}>Del</div>
      <ToastContainer />
    </>
  )
}

export default Page;

export const pageQuery = graphql`
  query Page($path: String!) {
    page: googleDocs(slug: {eq: $path}) {
      name
      cover {
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      childMarkdownRemark {
        html
      }
    }
  }
`